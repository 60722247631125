


export default {

  data () {
    return {
      testimonials: [
        {
          text: 'infoDiagram PowerPoint templates are very convenient to use. Besides, they all have a modern design and can come in handy in a variety of cases.',
          url: 'https://fixthephoto.com/infodiagram-powerpoint-templates-review.html',
          name: 'Kate Gross, journalist for <a href="https://fixthephoto.com/">fixthephoto.com</a>'
        },
        {
          text: 'The templates themselves are excellent and have added value to my slide deck.',
          url: 'https://www.trustpilot.com/reviews/6241b183843a1a3bf6c89c73',
          name: 'Stephanie, Germany'
        },
        {
          text: 'Your slides are my \'secret sauce\' when it comes to visualising my ideas to clients. I\'m saving so much time.',
          name: 'Emma, Agile Enterprises, Australia'
        },
        {
          text: 'The design is really perfect. Nothing to add to it.',
          url: 'https://www.trustpilot.com/reviews/62aa9bcc787382efe20ded75',
          name: 'Willy Makend, Germany'
        },
        {
          text: 'I had a time sensitive delivery and did not have the cycles to create a variety of template slides. Infodiagram offered options that were easy to download and customize for my needs!',
          name: 'Lorraine, United States'
        },
        {
          text: 'I love InfoDiagram. Their slides, content and customer service is excellent.',
          name: 'Joanita, South Africa'
        },
        {
          text: 'I have chosen infodiagram because of design quality and clear knowledge of what is required in terms of sections and tables for such type of presentation. Great job indeed.',
          name: 'Nicolas, Switzerland'
        }
      ]
    }
  }
}
