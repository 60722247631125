
export default {
  props: {
    testimonials: { type: Array, required: true }
  },
  computed: {
    jsonld () {
      return {
        '@context': 'https://schema.org/',
        '@type': 'Organization',
        review: this.getReviews(),
        name: 'infoDiagram',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: "5",
          bestRating: "5",
          ratingCount: this.testimonials.length
        }
      }
    }
  },
  methods: {
    getReviews () {
      let reviews = []
      reviews = this.testimonials.map((item) => {
        return {
          '@type': 'Review',
          itemReviewed: {
            '@type': 'Organization',
            logo: 'https://infodiagram.com/logo.svg',
            name: 'infoDiagram',
            legalName: 'infoDiagram Ltd'
          },
          
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '5'
          },
          name: item.text,
          author: {
            '@type': 'Person',
            name: item.name
          }
        }
      })
      return reviews
    }
  }
}
